import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { addAppLocaleData } from "./localeData";

require("dotenv").config();

addAppLocaleData();
const formats = {
  time: {
    "24hour": { hour12: false, hours: "numeric", minute: "numeric" }
  }
};

ReactDOM.render(
  <IntlProvider locale="it" formats={formats}>
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
