import { addLocaleData } from "react-intl";

import enLocaleData from "react-intl/locale-data/en";
import itLocaleData from "react-intl/locale-data/it";

export const localeData = [
  enLocaleData,
  itLocaleData
];

export const addAppLocaleData = () =>
  localeData.forEach(locale => addLocaleData(locale));
